{
  "name": "esri-leaflet-geocoder",
  "description": "Esri Geocoding utility and search plugin for Leaflet.",
  "version": "2.2.9",
  "author": "Patrick Arlt <parlt@esri.com> (http://patrickarlt.com)",
  "contributors": [
    "Patrick Arlt <parlt@esri.com> (http://patrickarlt.com)",
    "John Gravois <jgravois@esri.com> (http://johngravois.com)"
  ],
  "dependencies": {
    "esri-leaflet": "^2.0.3",
    "leaflet": "^1.0.0"
  },
  "devDependencies": {
    "chai": "3.5.0",
    "gh-release": "^2.0.0",
    "http-server": "^0.10.0",
    "imagemin": "^3.2.0",
    "isparta": "^4.0.0",
    "istanbul": "^0.4.2",
    "karma": "^1.3.0",
    "karma-chai-sinon": "^0.1.3",
    "karma-chrome-launcher": "^2.2.0",
    "karma-coverage": "^1.1.1",
    "karma-mocha": "^1.3.0",
    "karma-mocha-reporter": "^2.2.1",
    "karma-sourcemap-loader": "^0.3.5",
    "mkdirp": "^0.5.1",
    "mocha": "^3.1.0",
    "node-sass": "^3.2.0",
    "parallelshell": "^2.0.0",
    "phantomjs": "^1.9.8",
    "rollup": "^0.25.4",
    "rollup-plugin-json": "^2.0.0",
    "rollup-plugin-node-resolve": "^1.4.0",
    "rollup-plugin-uglify": "^0.3.1",
    "semistandard": "^9.0.0",
    "sinon": "^1.11.1",
    "sinon-chai": "2.8.0",
    "snazzy": "^5.0.0",
    "uglify-js": "^2.6.1",
    "watch": "^0.17.1"
  },
  "homepage": "https://github.com/Esri/esri-leaflet-geocoder",
  "jsnext:main": "src/EsriLeafletGeocoding.js",
  "jspm": {
    "registry": "npm",
    "format": "es6",
    "main": "src/EsriLeafletGeocoding.js"
  },
  "license": "Apache-2.0",
  "main": "dist/esri-leaflet-geocoder-debug.js",
  "browser": "dist/esri-leaflet-geocoder-debug.js",
  "readmeFilename": "README.md",
  "repository": {
    "type": "git",
    "url": "git@github.com:Esri/esri-leaflet-geocoder.git"
  },
  "scripts": {
    "prebuild": "mkdirp dist",
    "build": "rollup -c profiles/debug.js & rollup -c profiles/production.js & npm run css & npm run img",
    "css": "node-sass ./src/esri-leaflet-geocoder.css ./dist/esri-leaflet-geocoder.css --output-style compressed",
    "img": "imagemin ./src/img ./dist/img",
    "lint": "semistandard | snazzy",
    "prepare": "npm run build",
    "pretest": "npm run build",
    "release": "./scripts/release.sh",
    "start-watch": "watch \"npm run build\" src",
    "start": "parallelshell \"npm run start-watch\" \"http-server -p 5678 -c-1 -o\"",
    "test": "npm run lint && karma start"
  },
  "semistandard": {
    "globals": [
      "expect",
      "L",
      "XMLHttpRequest",
      "sinon",
      "xhr"
    ]
  },
  "style": "./dist/esri-leaflet-geocoder.css"
}
